import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [

        {
            title: "Puķuzirņi",
            image: "https://i.imgur.com/dk2pHspb.jpg",
            native: "Vidusjūras apgabals",
            description: "Debešķīgi skaisti, bet vāzes nav ilgs dzīves ilgums aptuveni 4-5 dienas. Izrādās puķuzirņi radušies saulainajā Sicīlijā. Pasaulē ir pierasts dāvināt puķuzirņus, kā pateicības vai atzinības zīmi. Interesanti, ka Francijā līgavām, puķuzirņi simbolizē veiksmi, izturību un reizē arī atvadas",
        },    
        {
            title: "Klematis jeb mežvītenis",
            image: "https://i.imgur.com/351Ve18b.jpg",
            description: "Izrādās, ka šos augus Latvijā plašāk sāka audzēt tikai no 1960. gada un šobrīd tie ir iemīļoti vīteņaugi dārzos. Tie iedalās lielziedu un mazziedu, kopā pasaulē ir ap 250-300 dažādu sķirņu. Lielākais vairums aug Eirāzijas kontinentā.",
        },    
        {
            title: "Āzijas gundega jeb Ranunculus",
            image: "https://i.imgur.com/ZiZdn58b.jpg",
            native: "Āzija",
            description: "Vāzē tās var dzīvot līdz pat 10-12 dienām. Tuvākajās dienās tās vēl atvērsies un paliks 2x lielākas kā saņemšanas brīdī",
        },
        {
            title: "Lefkoja jeb matiolas",
            image: "https://i.imgur.com/4l4p5ibb.jpg",
            native: "Vidusjūras apgabals",
            description: "Šoreiz Tev piegādājām salīdzinoši maz izziedējušas lefkojas, lai tās Tev vāzē stāvētu vēl ilgāk.",
        },

        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },

        {
            title: "Pitosporas zari",
            image: "https://i.imgur.com/vOdvIqyb.jpg",
            native: "Austrālija",
            description: "Šajos zaros ir kas japānisks. Tos mēdz dēvēt par Pitto. Tie lieliski piedod apjomu ziedu kompozīcijām un bieži tiek izmantoti, kā atbalsts, mazāk stingriem ziediem. Arī šajā kompozīcijā uz tiem atbalstījām sniega bumbas.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām.",
        },
        {
            title: "Panicum Fountain",
            image: "https://i.imgur.com/xYpSYGkb.png",
            native: "Austrālija",
            description: "Gaisīgie stiebri, piešķir apjomu un vieglumu jebkurai kompozīcijai ",
        },
    
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/f941d86f-d8b2-42fb-9272-edcc53b9717a/playlist.m3u8"
                        captions="/titles/010.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Izmēģināsim oāzi</h2>

                            <p>Šoreiz vēlamies dot iespēju ziedus sakārtot oāzē. Pieļaujam, ka tas var būt kas jauns, bet cerams, ar nedadudz vairāk pacietības, laika un mūsu sagatavoto video, sanāks to izdarīt! Tevis saņemtais puķu pods un oāze, ir šīs nedēļas davaniņa. </p>

                            <p><strong>Ja nevēlies izmantot oāzi:</strong> šos pašus ziedus vari sakārtot arī vāzē ar ūdeni. Seko <a href="http://127.0.0.1:8000/hi/9">iepriekšējās nedēļas video</a> ar pamata ziedu kompozīcijas izveidi vāzē.</p>

                            
                            <p>Lai padarītu kompozīcijas izveidi vienkāršāku, izvēlējāmies izturīgākus ziedus - rozes, ranunkuļus, klematis, lefokjas un dažādus zaļumus. Mēs gan nenoturējāmies pretī puķuzirņu kārdinājumam. </p>


                            <p>Pirms sāc:</p>

                            <ul>
                                <li>Lefkojas transportēšanas dēļ var izskatīties noliekušās. Tas nekas - kamēr gatavo oāzi, atjauno griezuma vietu un ieliec tās vāzē ar ūdeni. Arī oāzē, jau pēc dažām stundām, tās atgūs savu formu.</li>
                                <li>Darbam ar oāzi būs nepieciešama dziļāka bļoda, lielāks nazis un kāds smails irbulsi.</li>
                                <li>Oāze ir jāizmērcē ūdenī, kurā ir izsķīdināts ziedu barības pulveris.</li>
                                <li>Droši izmanto citu trauku, ja vēlies. Oāzi var likt bļodā, terīnē, ziedu kastē vai kur citur.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba:</p>

                            <ol>
                                <li>Seko video, lai redzētu darba procesu. </li>
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">Aizpildīt anketu</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/wKqqv7qh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe oāzei</h2>

                            <p>Ziediem oāzē jāpārbauda oāzes mitruma līmenis.</p>

                            <ol>
                                <li>Reizi divās dienās ar pirkstu pārbaudi vai oāzes augšpuse ir mitra</li>
                                <li>Ja tā ir sausa, neizņemot ziedus no trauka, ieliec trauku izlietnē</li>
                                <li>Lēnām, vienā trauka pusē, lej vēsu ūdeni</li>
                                <li>Oāze lēnām piesūksies ar ūdeni.</li>
                                <li>Kad otrā trauka pusē redzēsi ūdeni, tā būs pilnībā </li>
                            </ol>

                            <p>PS: Atšķīrībā no ziediem vāzē, nav nepieciešams ziediem atjaunot griezuma vietu.</p>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
